import React, { Fragment, useState } from 'react';
import { PageProps } from 'gatsby';
import { TranslationService } from '@/src/services/translation.service';
import { TranslationProvider } from '@/src/contexts/translation.context';
import Header from '@/src/components/header/header';
import { Subheader } from '@/src/components/subheader/subheader';
import Navbar from '@/src/components/navbar/navbar';
import Footer from '@/src/components/footer/footer';
import { useEffect } from 'react';
import { getTravelService } from '@/src/services/travel.service';
import { BookedTravel } from '@/src/models/departures.model';
import { parse } from 'date-fns/esm';
import { format } from 'date-fns';
import * as styles from './booked-trips.module.scss';
import { TravelItem } from '../book-ticket/travel-item/travel-item';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import { SignupFunnelCard } from '@/src/components/signup-funnel-card/signup-funnel-card';
import { Link } from 'gatsby';
import { isAfter, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Helmet } from 'react-helmet';


export default function BookedTripsPage({ pageContext }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [bookedTrips, setBookedTrips] = useState({} as { [date: string]: BookedTravel[]; });
  const { isAuthenticated } = useAuthStatus();

  const tabs = [
    {
      name: translation.translate('book_ticket.book_tickets'),
      href: `/${translation.lang}/book-ticket`
    },
    {
      name: translation.translate('book_ticket.upcoming_travel'),
      href: `/${translation.lang}/booked-trips`
    }
  ];

  useEffect(() => {
    const travelService = getTravelService();
    travelService.init();
    const subscription = travelService.bookedTrips$.subscribe((bt) => {
      let groupedTrips = {};
      let europeanGroupedTrips = {};

      const dateNow = zonedTimeToUtc(new Date(), 'Europe/Helsinki');

      // Group
      for(const bookedTrip of bt) {
        if(!groupedTrips[bookedTrip.date]) groupedTrips[bookedTrip.date] = [];
        groupedTrips[bookedTrip.date].push(bookedTrip);
      }

      // Sort by time
      for(const date of Object.keys(groupedTrips)) {
        groupedTrips[date] = groupedTrips[date].sort((a: BookedTravel, b: BookedTravel) => {
          const aDate = new Date(a.schedule.departure);
          const bDate = new Date(b.schedule.departure);
          return aDate.getTime() - bDate.getTime();
        });
      }

      // Sort by date
      const orderedAndGroupedTrips = Object.keys(groupedTrips)
        .sort((a, b) => {
          const aDate = parse(a, 'yyyy-MM-dd', new Date());
          const bDate = parse(b, 'yyyy-MM-dd', new Date());
          return aDate.getTime() - bDate.getTime();
        })
        .reduce((obj, key) => {
          obj[key] = groupedTrips[key];
          return obj;
        }, {});

      // Convert date string to european format
      for(const date of Object.keys(orderedAndGroupedTrips)) {
        if(isAfter(parseISO(date), dateNow) || format(dateNow, 'yyyy-MM-dd') === date) {
          const europeanDateString = format(parse(date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
          europeanGroupedTrips[europeanDateString] = groupedTrips[date];
        }
      }
      setBookedTrips(europeanGroupedTrips);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const unbook = (trip: BookedTravel) => {
    const travelService = getTravelService();
    travelService.unbookTrip(trip);
  };

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('helmet.booked_trips_title')}</title>
        <meta name="description" content={translation.translate('helmet.booked_trips_description')} />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>
      <Header></Header>
      <div className={`scrollable ${styles.content}`}>
        <div className={`content ${styles.content}`}>
          <Subheader.Tabs activeHref={`/${translation.lang}/booked-trips`} tabs={tabs}></Subheader.Tabs>
          <div className={`app__wrapper ${styles.content}`}>
            {isAuthenticated &&
              <div className={styles.inner}>
                <h1 className={styles.heading}>{translation.translate('book_ticket.upcoming_travel_heading')}</h1>
                <p className={styles.text}>
                  {translation.translate('book_ticket.upcoming_travel_text')}
                  <Link to={`/${translation.lang}/purchase/cart`}>{translation.translate('book_ticket.upcoming_travel_text_link')}</Link>
                </p>
              </div>
            }

            <div className={styles.innerBookedTrips}>

              {!isAuthenticated ?
                <SignupFunnelCard actionKey="booked_trips"></SignupFunnelCard>
                : null}


              {Object.keys(bookedTrips).map(dateString => {
                return <Fragment key={dateString}>
                  <p className={styles.dateHeader}>{dateString}</p>
                  <div className={styles.travelContainer}>
                    {bookedTrips[dateString].map((bookedTrip, index) => {
                      const schedule = bookedTrip.schedule;

                      const parts = [];

                      for(const part of schedule.parts) {
                        const departure = {
                          departureTime: part.Destination.Time,
                          departureName: part.SequenceFragment[0].StopPoint.Name,
                        };
                        const destination = {
                          destinationTime: part.SequenceFragment[part.SequenceFragment.length - 1].Time,
                          destinationName: part.SequenceFragment[part.SequenceFragment.length - 1].StopPoint.Name,
                        };
                        const tripInformation = part.ContractorName;
                        const tripDuration = part.SequenceFragment[part.SequenceFragment.length - 1].SecondsSinceStart;
                        const stops = [];

                        if(part.SequenceFragment.length >= 3) {
                          for(let i = 1; i < part.SequenceFragment.length - 1; i++) {
                            stops.push({
                              name: part.SequenceFragment[i].StopPoint.Name,
                              time: part.SequenceFragment[i].Time,
                            });
                          }
                        }

                        parts.push({
                          gid: part.Gid,
                          departure,
                          destination,
                          tripInformation,
                          tripDuration,
                          stops,
                        });
                      }

                      const departureDate = new Date(schedule.departure);
                      const now = new Date();
                      const disabled = now.getTime() > departureDate.getTime();

                      return (
                        <TravelItem
                          disabled={disabled}
                          key={index}
                          parts={parts}
                          buyHidden={true}
                          bookHidden={true}
                          onUnbookClick={() => unbook(bookedTrip)}></TravelItem>
                      );
                    })}
                  </div>
                </Fragment>;
              })}
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
      <Navbar></Navbar>
    </TranslationProvider>
  );
}